import axios from "axios";

import { authHeader } from "./_helper";

// const rootURL = "http://localhost:2250/api/v1/";
const emailURL = "https://emails.fillform2earn.com/api/";
const rootURL = "https://server.fillform2earn.com:2250/api/v1/";

const dashboardURL = rootURL + "dashboard";
const authURL = rootURL + "auth";
const userProjectURL = rootURL + "user-project"
const accountURL = rootURL + "account" 
const withdrawlListURL = rootURL + "withdrawl-list"
const formURL = rootURL + "forms"
const formCollectionURL = rootURL+ "form-collections"
const userFormFilesURL = rootURL +'user-form-files'



/***** Login *****/
function verifyId(email, password) {
  return axios.post(authURL + "/user/login", { email, password });
}

function logout() {
  return axios.get(authURL + "/logout", { headers: authHeader() });
}
function loginHistory(limit, offset) {
  return axios.get(
    rootURL + "login-history?limit=" + limit + "&offset=" + offset,
    { headers: authHeader() }
  );
}
/***** Login *****/
function getDashboardCounts(){
  return axios.get(dashboardURL,{ headers: authHeader() })
}
function getUserProfile(){
  return axios.get(accountURL+ '/profile',{ headers: authHeader() })
}

function getUserProject(formFileId){
  return axios.get(formCollectionURL+ '/all/'+formFileId,
    { headers: authHeader() });
}
function getUserProjectList(){
  return axios.get(formURL ,
    { headers: authHeader() });
}
function getOneProject(id){
  return axios.get(formURL + "/" +id,
  { headers: authHeader() });
}
function getFillFormList(imageName){
  return axios.get(formURL + "/all/" +imageName,
  { headers: authHeader() });
}
function getProjectDetails(id){
  return axios.get(userProjectURL + "/"+id,
    { headers: authHeader() }
  );
}

function submitUserProjectEnd(id){
  return axios.put(userProjectURL + "/" + id,{},
  { headers: authHeader() }
);
}

function getwithdrawlList(limit, offset,keyword){
  return axios.get(
    withdrawlListURL + "?limit=" + limit + "&offset=" + offset + "&keyword=" + keyword,
    { headers: authHeader() }
  );
}
function acceptTrams(id,tnc){
  return axios.put(userProjectURL + "/"+id,{tnc},
    { headers: authHeader() }
  );
}
function getFromDetails(id){
  return axios.get(formURL + "/"+id,
  { headers: authHeader() }
);
}
function submitProject(id,imageName,serialNo, title, firstName, lastName, initial, email, fatherName, dob, gender, profession, mailingStreet, mailingCity, mailingPostalCode, mailingCountry, serviceProvider, fileNo, referenceNo, simNo, typeOfNetwork, cellModelNo, imei1, imei2, typeOfPlan, creditCardType, contractValue, dateOfIssue, dateOfRenewal, installment, amountInWords, remarks, formFileId){
  return axios.post(formURL+ "/"+id ,{imageName,serialNo, title, firstName, lastName, initial, email, fatherName, dob, gender, profession, mailingStreet, mailingCity, mailingPostalCode, mailingCountry, serviceProvider, fileNo, referenceNo, simNo, typeOfNetwork, cellModelNo, imei1, imei2, typeOfPlan, creditCardType, contractValue, dateOfIssue, dateOfRenewal, installment, amountInWords, remarks, formFileId},{ headers: authHeader() })
}
function updateProject(data){
  return axios.put(formURL+ "/"+data.id ,data,{ headers: authHeader() })
}

function Skip(id){
  return axios.get(formURL+ "/skip/"+id ,{ headers: authHeader() })
}
function EndProject(id){
  return axios.get(formURL+ "/complete/"+id ,{ headers: authHeader() })
}
function widreq(amount){
  return axios.post(withdrawlListURL, {amount},{ headers: authHeader() })
}

function proofone(id, file) {
  const formData = new FormData();
  formData.append("file", file);
  return axios.put(userProjectURL + "/proof1/" + id, formData, {
    headers: authHeader("FormData"),
  });
}

function prooftwo(id, file) {
  const formData = new FormData();
  formData.append("file", file);
  return axios.put(userProjectURL + "/proof2/" + id, formData, {
    headers: authHeader("FormData"),
  });
}

function proofthree(id, file) {
  const formData = new FormData();
  formData.append("file", file);
  return axios.put(userProjectURL + "/proof3/" + id, formData, {
    headers: authHeader("FormData"),
  });
}

function prooffour(id, file) {
  const formData = new FormData();
  formData.append("file", file);
  return axios.put(userProjectURL + "/proof4/" + id, formData, {
    headers: authHeader("FormData"),
  });
}

function getAllUserCollection(limit,offset,keyword,status){
  return axios.get(
    userFormFilesURL + "/user?limit=" + limit + "&offset=" + offset + "&keyword=" + keyword+ "&status=" + status,
    { headers: authHeader() }
  );
}

function updateMyPass(oldPassword, password, confirmPassword) {
  return axios.patch(
    accountURL + "/password",
    { oldPassword, password, confirmPassword },
    { headers: authHeader() }
  );
}

function sendPass(email) {
  return axios.get(accountURL + "/profile/" + email);
}

// send mail
async function sendforgotmail(receiverName, receiverEmail, password) {
  return await axios.post(emailURL + "forgetMail", { receiverName, receiverEmail, password });
}

export const apiServices = {
  // admin login
  verifyId,
  logout,
  getUserProfile,
  loginHistory,
  getUserProject,
  getUserProjectList,
  getOneProject,
  getFillFormList,
  getwithdrawlList,
  getProjectDetails,
  submitUserProjectEnd,
  acceptTrams,
  getFromDetails,
  submitProject,
  updateProject,
  widreq,
  Skip,
  EndProject,
  getDashboardCounts,
  // Staff
  proofone,
  prooftwo,
  proofthree,
  prooffour,
  getAllUserCollection,

  updateMyPass,
  sendPass,
  sendforgotmail,
};
